import React, { lazy, Suspense } from 'react'
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom'
import AppWrapper from './router';

import './App.css';

function App() {
  return (
    <div className="App">
      <HashRouter>
          <Suspense fallback={<div>Loading...</div>}>
              <AppWrapper/>
          </Suspense>
      </HashRouter>
    </div>
  );
}

export default App;
