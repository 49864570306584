import {lazy} from 'react'
import {useRoutes} from "react-router-dom"

const Login = lazy(() => import('@/views/login'))
// 框架
const Layout = lazy(() => import('@/views/layout/layout'))
//数据中心
const DataCenter = lazy(() => import('@/views/dataCenter/DataCenter'))
//客服中心
const OrderManage = lazy(() => import('@/views/callCenter/OrderManage'))
// 审核中心
const StoreAudit = lazy(() => import('@/views/auditCenter/StoreAudit'))
const IdentityAudit = lazy(() => import('@/views/auditCenter/IdentityAudit'))
//运营中心
const SubjectManage = lazy(() => import('@/views/operationCenter/SubjectManage'))
const ProjectManage = lazy(() => import('@/views/operationCenter/ProjectManage'))
//管理中心
const CompanyManage = lazy(() => import('@/views/manageCenter/CompanyManage'))
const DepartmentManage = lazy(() => import('@/views/manageCenter/DepartmentManage'))

// 项目列表
const Project = lazy(() => import('@/views/project'))
const ProjectList = lazy(() => import('@/views/project/list'))

const App = () => useRoutes([
    //重定向
    {
        path: '/',
        element: <Project/>,
        children: [
            {
                path: '',
                element: <ProjectList/>
            }
        ]
    },

    {
        path: '/yezhimo',
        element: <Layout/>,
        children: [
            {
                path: '',
                element: <DataCenter/>
            }
        ]
    },
    // 登录
    {
        path: '/login',
        element: <Login/>
    },
    //客服中心
    {
        path: '/call',
        element: <Layout/>,
        children: [
            {
                path: 'order',
                element: <OrderManage/>
            }
        ]
    },
    //审核中心
    {
        path: '/audit',
        element: <Layout/>,
        children: [
            {
                path: 'store',
                element: <StoreAudit/>
            },
            {
                path: 'identity',
                element: <IdentityAudit/>
            }
        ]
    },
    // 运营中心
    {
        path: '/operation',
        element: <Layout/>,
        children: [
            {
                path: 'subject',
                element: <SubjectManage/>
            },
            {
                path: 'project',
                element: <ProjectManage/>
            }
        ]
    },
    // 管理中心
    {
        path: '/manage',
        element: <Layout/>,
        children: [
            {
                path: 'company',
                element: <CompanyManage/>
            },
            {
                path: 'department',
                element: <DepartmentManage/>
            }
        ]
    }
])
//路由表
export default App